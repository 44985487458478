import { css } from '@emotion/react';
import '@livechat/design-system-react-components/dist/style.css';

export const flexContainer = css`
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: max-content;
`;

export const wrapper = css`
  flex: 1;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  text-align: center;
`;

export const loaderContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
