/* eslint-disable spaced-comment */
import { Loader } from '@livechat/design-system-react-components';
import { lazy, Suspense } from 'react';

import Location from './modules/Location';

import * as styles from './App.styles';

const App = lazy(() => import(/*@vite-ignore*/ './App'));
const IframeRouter = lazy(() => import(/*@vite-ignore*/ './router/IframeRouter'));

const AppLazy = () => (
  <Suspense fallback={<Loader size="large" css={styles.loaderContainer} />}>
    {Location.isInLoginButtonIframe() ? <IframeRouter /> : <App />}
  </Suspense>
);

export default AppLazy;
