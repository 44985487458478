import './polyfills/index';

import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import { IWindow } from './interfaces/window';
import CommmonStore from './stores/CommonStore';
import Config from './Config';
import AppLazy from './AppLazy';

declare const window: IWindow;

if (import.meta.env.VITE_APP_RELEASE) {
  if (!Element.prototype.remove) {
    Element.prototype.remove = function () {
      this.parentElement.removeChild(this);
    };
  }

  Sentry.init({
    dsn: `${import.meta.env.VITE_APP_SENTRY_API_URL}`,
    release: import.meta.env.VITE_APP_RELEASE,
    debug: Config.isDev(),
    allowUrls: ['livechat.com'],
    beforeSend(event) {
      const userAgent = navigator.userAgent || '';

      // Check if the user agent matches Chrome Mobile WebView
      const isChromeMobileWebView =
        /; wv\)/.test(userAgent) && /Chrome\/[\d.]+ Mobile/.test(userAgent);

      if (isChromeMobileWebView) {
        // If the user agent matches, ignore the event by returning null
        return null;
      }

      // Otherwise, send the event to Sentry
      return event;
    },
    ignoreErrors: [
      /Свернуть is not defined/, // Ignore error related to Violentmonkey chrome extension
      /GM_config is not defined/, // Ignore error related to Violentmonkey chrome extension
      /Hotjar is not available, make sure init has been called/,
      /CrossStorageClient could not connect/, // https://github.com/zendesk/cross-storage/issues/58
      /Closing client. Could not access localStorage in hub./, // https://github.com/zendesk/cross-storage/issues/58
      /could not perform cross-storage:set/, // https://github.com/zendesk/cross-storage/issues/43
      /Load failed/, // https://stackoverflow.com/questions/71280168/javascript-typeerror-load-failed-error-when-calling-fetch-on-ios
      /NetworkError when attempting to fetch resource/, // Fetch issue caused by adblock - https://stackoverflow.com/a/62646075/4163255
      /Failed to read the 'localStorage' property from 'Window': Access is denied for this document./, // https://stackoverflow.com/questions/30481516/iframe-in-chrome-error-failed-to-read-localstorage-from-window-access-deni
      /Non-Error promise rejection captured with value: Object Not Found Matching Id/,
      /ResizeObserver loop completed with undelivered notifications/,
      /ResizeObserver loop limit exceeded/,
      /(subscriptions|subscription|billing data) couldn't be fetched/i,
      /rambox is not defined/, // Ignore error related to Rambox chrome extension
    ],
  });

  Sentry.configureScope((scope) => {
    scope.setTag('version', 'global');
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AppLazy />);

// TODO: To remove?
window.Accounts = {
  // This is public API of our React App
  setAgentLogin(agentLogin: string) {
    CommmonStore.set({ email: agentLogin });
  },
};
