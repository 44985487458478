// eslint-disable-next-line import/no-cycle
import Utils from './Utils';

class Location {
  // getter that can be overwritten for test purposes
  getWindow(): any {
    return window;
  }

  getPathName() {
    return window.location.pathname.substring(1);
  }

  getReferrer() {
    return this.getWindow().document.referrer;
  }

  getReferrerHostname(): string {
    let url;

    try {
      url = new URL(this.getReferrer());
    } catch (e) {
      return '';
    }

    return url.hostname;
  }

  getUrlParam(name) {
    try {
      return (
        decodeURIComponent(
          (new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(window.location.search) || [
            null,
            '',
          ])[1].replace(/\+/g, '%20')
        ) || undefined
      );
    } catch (e) {
      if (this.getWindow().console && console.error) {
        console.error(`Malformed URI param: ${name}`);
      }
      return undefined;
    }
  }

  getHashParam(key) {
    const params = window.location.hash.substring(1).split('&');
    const value = params.find((item) => item.split('=')[0] === key);
    return value ? decodeURIComponent(value.split('=')[1]) : null;
  }

  getUtmSearchParams() {
    const url = new URL(window.location.href);
    return this.getUtmParams(url.search);
  }

  getUtmHashParams() {
    const url = new URL(window.location.href);
    return this.getUtmParams(url.hash.slice(1));
  }

  getUtmParams(paramsString: string) {
    const params = Array.from(new URLSearchParams(paramsString).entries());

    return params.reduce((acc, [key, value]) => {
      if (key.startsWith('utm_')) {
        return {
          ...acc,
          [key]: value,
        };
      }
      return acc;
    }, {});
  }

  getNthUrlParam(paramIndex) {
    const path = window.location.pathname.substring(1);
    const parts = path.split('/');
    if (paramIndex < parts.length) {
      try {
        return decodeURIComponent(parts[paramIndex]);
      } catch (e) {
        return parts[paramIndex];
      }
    } else {
      return '';
    }
  }

  getLastUrlParam() {
    const path = window.location.pathname.substring(1);
    const parts = path.split('/');
    if (parts.length > 0) {
      try {
        return decodeURIComponent(parts[parts.length - 1]);
      } catch (e) {
        return parts[parts.length - 1];
      }
    } else {
      return '';
    }
  }

  getRedirectUri(): string {
    return (
      this.getHashParam('redirect_uri') ||
      this.getUrlParam('redirect_uri') ||
      this.getJWTParam('redirect_uri')
    );
  }

  getState(): string {
    return this.getHashParam('state') || this.getUrlParam('state');
  }

  getJWTParam(param) {
    try {
      const jwt = Utils.parseJwt(this.getHashParam('state'));
      return jwt[param];
    } catch (e) {
      return null;
    }
  }

  isInLoginButtonFlow() {
    return (
      /^login-button\//.test(this.getPathName()) ||
      this.getUrlParam('flow') === 'button' ||
      this.getJWTParam('flow') === 'button'
    );
  }

  currentModuleSupportsIframeHiddenFlow() {
    const pathName = this.getPathName();

    return !/^billing\//.test(pathName);
  }

  isInLoginButtonPopup() {
    return this.getWindow().opener != null && this.isInLoginButtonFlow();
  }

  isInLoginButtonIframe() {
    return (
      this.getWindow().top !== this.getWindow().self &&
      this.isInLoginButtonFlow() &&
      this.getRedirectUri() != null &&
      this.currentModuleSupportsIframeHiddenFlow()
    );
  }

  isInIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  closePopupWindow() {
    this.getWindow().close();
  }

  sendPostMessage(data) {
    const json = { ...data };
    const domain = this.getRedirectUri();

    const state = this.getUrlParam('state') || this.getHashParam('state');
    if (state) {
      json.state = state;
    }

    if (this.isInLoginButtonIframe()) {
      this.getWindow().parent.postMessage(json, domain);
    } else if (this.isInLoginButtonPopup()) {
      this.getWindow().opener.postMessage(json, domain);
      this.closePopupWindow();
    }
  }

  isInLogin = () => {
    const pathName = this.getPathName();

    return pathName === '' || /^signin/.test(pathName);
  };

  isInLogout = () => {
    const pathName = this.getPathName();

    return /^logout/.test(pathName);
  };

  isInSignup = () => {
    const pathName = this.getPathName();

    return /^signup/.test(pathName);
  };

  isTrackingRouteBlocked() {
    const currentUrl = this.getPathName();
    const blockedRoutes = ['set-password'];

    const isBlocked = !!blockedRoutes.find((route) => new RegExp(`^${route}`).test(currentUrl));

    return isBlocked;
  }

  handleCustomSSORedirect = () => {
    const currentUrlParams = new URLSearchParams(window.location.search);
    const searchParamsString = currentUrlParams.toString();
    const newPath = `/custom-sso/${window.location.pathname.split('/').slice(2).join('/')}`;

    // Construct the full URL with parameters
    const redirectUrl = searchParamsString ? `${newPath}?${searchParamsString}` : newPath;

    // Redirect the user to the new URL
    window.location.href = redirectUrl;
  };
}

export default new Location();
