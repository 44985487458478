import { getItem, saveItem } from 'utils/session-storage';
import Location from './Location';

const NO_SIGN_UP_KEY = 'no_sign_up';

class Utils {
  submitPost = (url, params) => {
    const form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', url);

    Object.keys(params).forEach((key) => {
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', key);
      hiddenField.setAttribute('value', params[key]);
      form.appendChild(hiddenField);
    });

    document.getElementById('root').appendChild(form);
    form.submit();
  };

  oauthQuery = (cfg: any = {}, opts: any = {}) => {
    const query = [];
    query.push(`client_id=${encodeURIComponent(cfg.client_id)}`);
    query.push(`redirect_uri=${encodeURIComponent(cfg.redirect_uri)}`);
    query.push(`response_type=${encodeURIComponent(cfg.response_type)}`);

    if (cfg.organization_id && opts.organization_id == null) {
      query.push(`organization_id=${encodeURIComponent(cfg.organization_id)}`);
    }

    if (cfg.flow) {
      query.push(`flow=${encodeURIComponent(cfg.flow)}`);
    }

    if (cfg.state) {
      query.push(`state=${encodeURIComponent(cfg.state)}`);
    }

    if (cfg.code_challenge) {
      query.push(`code_challenge=${encodeURIComponent(cfg.code_challenge)}`);
    }

    if (cfg.code_challenge_method) {
      query.push(`code_challenge_method=${encodeURIComponent(cfg.code_challenge_method)}`);
    }

    if (cfg.prompt) {
      query.push(`prompt=${encodeURIComponent(cfg.prompt)}`);
    }

    if (cfg.no_sign_up === '1') {
      query.push(`no_sign_up=${encodeURIComponent(cfg.no_sign_up)}`);
    }

    if (cfg.new_signin === '1') {
      query.push(`new_signin=${encodeURIComponent(cfg.new_signin)}`);
    }

    Object.keys(opts).forEach((key) => {
      if (opts[key]) {
        query.push(`${key}=${encodeURIComponent(opts[key])}`);
      }
    });

    return query.join('&');
  };

  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  // Created to hide SignUp section in LiveChat Mobile app on iOS platform
  // (to be compliant with 5.1.1 App Store review guideline)
  shouldHideSignUp() {
    const noSignUpFromUrl = Location.getUrlParam(NO_SIGN_UP_KEY);

    const noSignUpFromStorage = getItem(NO_SIGN_UP_KEY);

    const clientId = Location.getUrlParam('client_id');

    if (
      noSignUpFromUrl === '1' ||
      noSignUpFromStorage === 1 ||
      clientId === import.meta.env.VITE_APP_CONTEXT_EVENT_CLIENT_ID
    ) {
      saveItem(NO_SIGN_UP_KEY, 1);

      return true;
    }

    return false;
  }
}

export default new Utils();
