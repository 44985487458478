import Cookies from 'js-cookie';
import Location from 'modules/Location';

class Config {
  accounts_api_url: string;
  location_service_api_url: string;
  livechat_api_url: string;
  text_api_url: string;
  billing_api_url: string;
  dev_platform_api_url: string;

  default_client_id: string;
  region: {
    current: string;
    default: string;
  };

  client_id: string;
  redirect_uri: string;
  response_type: string;
  organization_id: string;
  flow: string;
  state: string;
  prompt: string;
  scope: string;
  restore_id: string;
  email: string;

  accounts_url: string;
  organization_app_client_id: string;

  code_challenge: string;
  code_challenge_method: string;

  // It's used to hide `Sign up` section.
  // It must be passed to backend because oAuth redirects
  // must also have this parameter present (if we opened login page with it).
  no_sign_up: string;

  constructor() {
    this.setup();
  }

  setup = async (): Promise<void> => {
    this.accounts_api_url = this.replaceLivechatinc(
      this.useCurrentOrigin(import.meta.env.VITE_APP_ACCOUNTS_API_URL)
    );
    this.livechat_api_url = import.meta.env.VITE_APP_LIVECHAT_API_URL;
    this.text_api_url = import.meta.env.VITE_APP_TEXT_API_URL;
    this.billing_api_url = import.meta.env.VITE_APP_BILLING_API_URL;
    this.default_client_id = import.meta.env.VITE_APP_DEFAULT_CLIENT_ID;
    this.client_id =
      Location.getUrlParam('client_id') || import.meta.env.VITE_APP_DEFAULT_CLIENT_ID;
    this.redirect_uri =
      Location.getUrlParam('redirect_uri') ||
      encodeURIComponent(import.meta.env.VITE_APP_DEFAULT_REDIRECT_URI);
    this.response_type = Location.getUrlParam('response_type') || 'code';
    this.organization_id = Location.getUrlParam('organization_id');
    this.flow = Location.getUrlParam('flow') || null;
    this.state = Location.getUrlParam('state') || null;
    this.prompt = Location.getUrlParam('prompt') || null;
    this.scope = Location.getUrlParam('scope') || null;
    this.restore_id = Location.getUrlParam('restore_id') || null;
    this.email = Location.getUrlParam('email') || null;
    this.region = {
      current: import.meta.env.VITE_APP_DEFAULT_REGION,
      default: import.meta.env.VITE_APP_DEFAULT_REGION,
    };

    this.readRegionFromUrl();

    this.dev_platform_api_url = import.meta.env.VITE_APP_TP_API_URL;
    this.location_service_api_url = import.meta.env.VITE_APP_LOCATION_SERVICE_API_URL;
    this.accounts_url = this.useCurrentOrigin(import.meta.env.VITE_APP_ACCOUNTS_API_URL);
    this.organization_app_client_id = import.meta.env.VITE_APP_ORGANIZATION_CHOOSER_CLIENT_ID;

    this.code_challenge = Location.getUrlParam('code_challenge') || null;
    this.code_challenge_method = Location.getUrlParam('code_challenge_method') || null;

    this.no_sign_up = Location.getUrlParam('no_sign_up') || null;
  };

  replaceLivechatinc = (uri): string => {
    if (/livechat\.com$/.test(window.location.origin) && /livechatinc\.com$/.test(uri)) {
      return uri.replace('livechatinc.com', 'livechat.com');
    }

    return uri;
  };

  useCurrentOrigin = (uri): string => {
    if (import.meta.env.VITE_APP_ENV === 'labs') {
      if (Cookies.get('relative-path')) {
        return window.location.origin;
      }
      return uri;
    }
    return uri;
  };

  readRegionFromUrl = (): void => {
    const region = Location.getUrlParam('region');
    if (region) {
      this.updateRegion(region);
    }
  };

  updateRegion = (dc): void => {
    if (this.regionIsValid(dc)) {
      this.region.current = dc;

      if (this.region.current === this.region.default) {
        this.livechat_api_url = this.livechat_api_url.replace(/\/api-[^.]+\./, '/api.');
      } else {
        this.livechat_api_url = this.livechat_api_url.replace(
          '/api.',
          `/api-${this.region.current}.`
        );
      }
    }
  };

  regionIsValid = (region: string): boolean => /^[a-zA-Z0-9-]{2,10}$/.test(region);

  isProduction(): boolean {
    return import.meta.env.VITE_APP_ENV === 'production';
  }

  isDev(): boolean {
    return (
      import.meta.env.VITE_APP_ENV === 'development' ||
      import.meta.env.VITE_APP_ENV === 'labs' ||
      import.meta.env.VITE_APP_ENV === 'staging'
    );
  }
}

export default new Config();
